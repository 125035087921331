import React from 'react'
import Layout from '../components/Layout/Layout'
import Seo from '../components/seo'
import PageHero from '../components/PageHero/PageHero'
import Rule from '../components/Rules/Rule'
import Rules from '../components/Rules/Rules'
import { graphql } from 'gatsby'

const integritetspolicy = ({ data: { page } }) => {
  const modified = page.modified
  const integrs = page.integritetspolicy.integritetspolicy
  return (
    <Layout>
      <Seo title={'Integritetspolicy'} />
      <PageHero title={'Integritetspolicy'} breakText />

      <section className="border-t border-opacity-10 border-lightest">
        <Rules>
          {integrs.map((item, index) => (
            <Rule key={index} title={item.rubrik} text={item.text} />
          ))}
          <Rule
            title={'Uppdateringar till denna policy'}
            text={`Vi kan komma att uppdatera denna policy och kommer då publicera dem på denna webbsida. Denna policy uppdaterades senast den ${modified}.`}
          />
        </Rules>
      </section>
    </Layout>
  )
}

export default integritetspolicy

export const pageQuery = graphql`
  query IntegrPage {
    page: wpPage(title: { eq: "Integritetspolicy" }) {
      id
      modified(formatString: "LL", locale: "sv")
      integritetspolicy {
        integritetspolicy {
          rubrik
          text
        }
      }
    }
  }
`
